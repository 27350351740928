import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  CURRENT_USER,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAILURE,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILURE,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  GET_USER_FAILURE,
  GET_USER_SUCCESS,
  GET_USER,
  ASSIGN_COMPANY,
  ASSIGN_COMPANY_SUCCESS,
  ASSIGN_COMPANY_FAILURE,
  REMOVE_ASSIGNED_COMPANY,
  REMOVE_ASSIGNED_COMPANY_SUCCESS,
  REMOVE_ASSIGNED_COMPANY_FAILURE,
  GET_USER_AUDIT_HISTORY,
  GET_USER_AUDIT_HISTORY_SUCCESS,
  GET_USER_AUDIT_HISTORY_FAILURE,
  GET_USER_CONTROL_BOARD,
  GET_USER_CONTROL_BOARD_SUCCESS,
  GET_USER_CONTROL_BOARD_FAILURE,
  GET_ALL_MODULES,
  GET_ALL_MODULES_SUCCESS,
  GET_ALL_MODULES_FAILURE,
  UPDATE_USER_MODULES,
  UPDATE_USER_MODULES_SUCCESS,
  UPDATE_USER_MODULES_FAILURE,
  ADD_USER_MODULE,
  ADD_USER_MODULE_SUCCESS,
  ADD_USER_MODULE_FAILURE,
  GET_USER_MODULES,
  GET_USER_MODULES_SUCCESS,
  GET_USER_MODULES_FAILURE,
  GET_WIN_RATE_PER_QUARTER,
  GET_WIN_RATE_PER_QUARTER_SUCCESS,
  GET_WIN_RATE_PER_QUARTER_FAILURE,
  ASSIGN_USER,
  ASSIGN_USER_SUCCESS,
  ASSIGN_USER_FAILURE,
  REMOVE_ASSIGNED_USER,
  REMOVE_ASSIGNED_USER_SUCCESS,
  REMOVE_ASSIGNED_USER_FAILURE,
  GET_HEALTH_STATUS,
  GET_HEALTH_STATUS_SUCCESS,
  GET_HEALTH_STATUS_FAILURE,
  GET_USER_COLLABORATORS,
  GET_USER_COLLABORATORS_SUCCESS,
  GET_USER_COLLABORATORS_FAILURE,
  ADD_KEYWORDS,
  ADD_KEYWORDS_SUCCESS,
  ADD_KEYWORDS_FAILURE,
  GET_KEYWORDS,
  GET_KEYWORDS_SUCCESS,
  GET_KEYWORDS_FAILURE,
  TOGGLE_FREE_TRIAL,
  TOGGLE_FREE_TRIAL_SUCCESS,
  TOGGLE_FREE_TRIAL_FAILURE,
} from "./actionTypes";

const initialState = {
  user: {},
  users: [],
  metadata: {},
  singleUser: {},
  userAuditHistory: [],
  allUserkeywords: [],
  userControlBoard: [],
  modules: [],
  singleModule: {},
  winRate: [],
  healthStatus: [],
  userCollabartors: [],
  keywords: [],
  isLoggedIn: null,
  loading: false,
  error: "",
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        isLoggedIn: true,
      };
      break;
    case LOGOUT_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGOUT_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: {},
        isLoggedIn: false,
      };
      break;
    case CURRENT_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CURRENT_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        isLoggedIn: true,
      };
      break;

    case CURRENT_USER_FAILURE:
      state = {
        ...state,
        loading: false,
        user: {},
        isLoggedIn: false,
      };
      break;

    case FORGET_PASSWORD:
      state = {
        ...state,
        loading: true,
      };
      break;

    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    case FORGET_PASSWORD_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;

    // ============================================================
    // ============================================================

    case GET_USERS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        users: action.payload?.items,
        metadata: {
          count: action.payload?.count,
          pageSize: action.payload?.pageSize,
          pageIndex: action.payload?.pageIndex,
        },
      };
      break;

    case GET_USERS_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case VERIFY_USER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case VERIFY_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    case VERIFY_USER_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case DELETE_USER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case DELETE_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        users: state.users.filter((user) => user?.id !== action.payload?.id),
      };
      break;

    case DELETE_USER_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case UPDATE_USER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    case UPDATE_USER_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case GET_USER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleUser: action.payload,
      };
      break;

    case GET_USER_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case ASSIGN_COMPANY:
      state = {
        ...state,
        loading: true,
      };
      break;

    case ASSIGN_COMPANY_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    case ASSIGN_COMPANY_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case REMOVE_ASSIGNED_COMPANY:
      state = {
        ...state,
        loading: true,
      };
      break;

    case REMOVE_ASSIGNED_COMPANY_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    case REMOVE_ASSIGNED_COMPANY_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case GET_USER_AUDIT_HISTORY:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_USER_AUDIT_HISTORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        userAuditHistory: action.payload,
      };
      break;

    case GET_USER_AUDIT_HISTORY_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case GET_USER_CONTROL_BOARD:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_USER_CONTROL_BOARD_SUCCESS:
      state = {
        ...state,
        loading: false,
        userControlBoard: action.payload,
      };
      break;

    case GET_USER_CONTROL_BOARD_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case GET_ALL_MODULES:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_ALL_MODULES_SUCCESS:
      state = {
        ...state,
        loading: false,
        modules: action.payload,
      };
      break;

    case GET_ALL_MODULES_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case UPDATE_USER_MODULES:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_USER_MODULES_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    case UPDATE_USER_MODULES_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case ADD_USER_MODULE:
      state = {
        ...state,
        loading: true,
      };
      break;

    case ADD_USER_MODULE_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    case ADD_USER_MODULE_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case GET_USER_MODULES:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_USER_MODULES_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleModule: action.payload,
      };
      break;

    case GET_USER_MODULES_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case GET_WIN_RATE_PER_QUARTER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_WIN_RATE_PER_QUARTER_SUCCESS:
      state = {
        ...state,
        loading: false,
        winRate: action.payload,
      };
      break;

    case GET_WIN_RATE_PER_QUARTER_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case ASSIGN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case ASSIGN_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    case ASSIGN_USER_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case REMOVE_ASSIGNED_USER:
      state = {
        ...state,
        loading: false,
      };
      break;

    case REMOVE_ASSIGNED_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        userCollabartors: state.userCollabartors.filter(
          (collaborator) => collaborator?.id !== action.payload?.collaboratorId
        ),
      };
      break;

    case REMOVE_ASSIGNED_USER_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case GET_HEALTH_STATUS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_HEALTH_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        healthStatus: action.payload,
      };
      break;

    case GET_HEALTH_STATUS_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case GET_USER_COLLABORATORS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_USER_COLLABORATORS_SUCCESS:
      state = {
        ...state,
        loading: false,
        userCollabartors: action.payload?.collaborators,
      };
      break;

    case GET_USER_COLLABORATORS_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case ADD_KEYWORDS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case ADD_KEYWORDS_SUCCESS:
      state = {
        ...state,
        loading: false,
        keywords: action.payload,
      };
      break;

    case ADD_KEYWORDS_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case GET_KEYWORDS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_KEYWORDS_SUCCESS:
      state = {
        ...state,
        loading: false,
        allUserkeywords: action.payload,
      };
      break;

    case GET_KEYWORDS_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    case TOGGLE_FREE_TRIAL:
      state = {
        ...state,
        loading: true,
      };
      break;

    case TOGGLE_FREE_TRIAL_SUCCESS:
      state = {
        ...state,
        loading: false,
        toggleTrial: action.payload,
      };
      break;

    case TOGGLE_FREE_TRIAL_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    // ============================================================
    // ============================================================

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default authentication;

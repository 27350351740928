import server from "./server";

export const getAdminsApi = async ({ pageNumber, pageSize }) => {
  const response = await server().get(
    `/CmsManagment/GetUsers?PageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  return response.data;
};

export const getAdminApi = async ({ id }) => {
  const response = await server().get(`/CmsManagment/GetCmsUserById/${id}`);
  return response.data;
};

export const addAdminApi = async ({ data }) => {
  const response = await server().post("/CmsManagment/AddUser", data);
  return response.data;
};

export const updateAdminApi = async ({ data }) => {
  const response = await server().put("/CmsManagment/UpdateCmsUser", data);
  return response.data;
};

export const deleteAdminApi = async ({ id }) => {
  const response = await server().delete(`/CmsManagment/DeleteUserById/${id}`);
  return response.data;
};
